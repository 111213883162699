import * as React from "react"
import { PageProps, Link, graphql, useStaticQuery, navigate } from "gatsby"
import ReactMarkdown from 'react-markdown'
 
import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"
import { useEffect, useState } from "react"
import { fetchUpcomingTrips } from "../backendApi/eventsApi"
import useAuth from "../hooks/useAuth"
import StrapiEvent from "../types/StrapiEvent"


function ClubEventsPage() {
  const { isAuthenticated } = useAuth()
  const { strapiClubEvents } = useStaticQuery(graphql`
  {
    strapiClubEvents {
      description {
        data {
          description
        }
      }
    }
  }`)

  const [upcomingTrips, setUpcomingTrips] = useState<StrapiEvent[]>([])
  useEffect(() => {
    (async () => {
      const res = await fetchUpcomingTrips()
      setUpcomingTrips(res.data as StrapiEvent[])
    })()
  }, [])

  return (
    <Layout>
      <Seo title="Club Events" />
      <NavBar />
      <ReactMarkdown>{strapiClubEvents.description.data.description}</ReactMarkdown>
      <h2>
        Upcoming Trips
      </h2>
      <div>
        {
          upcomingTrips && upcomingTrips.map((trip, i) => (
            <div>
              <h3 style={{marginBottom: "0px"}}>
                {new Date(trip.attributes.startDate).toLocaleDateString("en-GB")} - {new Date(trip.attributes.endDate).toLocaleDateString("en-GB")}: {trip.attributes.title}
              </h3>
              <p>
                <div>
                  Location: {trip.attributes.location}
                </div>
                <div>
                  Signup link: { isAuthenticated ? (<Link to={`/app/event-signup/${trip.id}`}>here</Link>) : "Login to see signup link"}
                </div>
              </p>
              <p>
                {trip.attributes.description}
              </p>
            </div>
          ))
        }
      </div>
      <iframe
        src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FLondon&showNav=1&title=WSG%20Calendar&hl=en_GB&src=Y18zajIyZWxmY3JrMTJzczA5aTJyOG8yYTIxZ0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=Y185ZnVwb3FqMWtqbzk0dDk1NDBycmN2aWE2MEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23A79B8E&color=%23AD1457"
        style={{ borderWidth: 0 }}
        width="800"
        height="600"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </Layout>
  )
} 

export default ClubEventsPage
